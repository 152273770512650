import _ from 'lodash';

export default {
	name: 'AwesomeVuetifyJitsi',

	props: {
		value: {
			type: Boolean,
			default: true
		},
		url: {
			type: String,
		}
	},

	data() {
		return {
			x: 0,
			y: 0,
			w: 520,
			h: 360,
			isFull: false,

			isDrug: false
		};
	},

	computed: {
		modeIcon() {
			return this.isFull ? 'fullscreen_exit' : 'fullscreen';
		},
	},

	methods: {
		toggleFullscreen() {
			this.isFull = !this.isFull;
		},

		close() {
			this.isFull = false;
			this.$emit('input', false);
			this.$emit('close');
		},

		onDragging(){
			this.isDrug = true;
		},
		onDragstop(){
			this.isDrug = false;
		},
	},
};

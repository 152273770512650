<template>
  <div>
    <DebugDetails
      :color="color"
      :title="title"
      :storeName="storeName"
      :fields="fields"
      :searchFields="searchFields" />

    <v-btn fab :color="color" fixed dark class="floatButton" @click="showEventDialog = true">
      <v-icon>add</v-icon>
    </v-btn>

    <EmitListenerDialog v-if="showEventDialog" v-model="showEventDialog" :color="color" />
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapActions } from 'vuex';
  import { ADD_TOAST_MESSAGE } from '@/plugins/vuex-toastr/module';
  import DebugDetails from '../DebugDetails/DebugDetails.vue';
  import EmitListenerDialog from './components/EmitListenerDialog/EmitListenerDialog.vue';

  export default {
    name: 'listener-details',
    props: ['color'],

    components: {
      DebugDetails,
      EmitListenerDialog,
    },

    data() {
      return {
        title: 'Listener',
        storeName: 'sListeners',
        fields: [
          { name: 'createdAt', format: this.formatDate },
          { name: 'agentId' },
          { name: 'eventId' },
          { name: 'eventSuffix', hideLabel: true },
          { name: 'params' },
        ],
        searchFields: ['agentId', 'eventId', 'eventSuffix'],

        showEventDialog: false,
        displayDataIndex: -1,
      };
    },

    methods: {
      ...mapActions({ addToast: ADD_TOAST_MESSAGE }),

      formatDate(date) {
        return moment(date).format('HH:mm:ss:SSS DD.MM.YYYY');
      },
      transform(data) {
        return JSON.stringify(data, null, 4);
      },
    },
  };
</script>

<style>
  .floatButton {
    bottom: 16px;
    right: 16px;
  }
</style>

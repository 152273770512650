<template>
  <div class="ma-0 pa-2">
    <transition-group>
      <template v-for="item in elements">
        <v-card :key="item.id" flat outlined class="mb-2 overflow-hidden">
          <div class="white">
            <DetailsListField
              v-for="(f, i) in fields"
              :key="f.name"
              :hideLabel="f.hideLabel"
              :label="f.name"
              :text="formatText(item[f.name], f.format)"
              :textClass="f.class"
              :props="f.props" /><!--:class="`grey lighten-${ (3 + i % 2).toFixed(0) }`"-->
          </div>
        </v-card>
      </template>
    </transition-group>

    <v-btn @click="onShowMore" :disabled="!isShowMore" block text color="secondary">
      [{{ elements.length }} / {{ items.length }}]
    </v-btn>
  </div>
</template>

<script>
  import DetailsListField from './components/DetailsListField.vue';

  export default {
    name: 'details-list',

    components: {
      DetailsListField,
    },

    props: {
      items: {
        type: Array,
        default: () => [],
      },
      fields: {
        type: Array,
        required: true,
      },
      isFullscreen: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        showNum: 10,
        step: 10,
      };
    },

    computed: {
      elements() {
        return this.items.slice(0, this.showNum);
      },

      isShowMore() {
        return this.elements.length < this.items.length;
      },
    },

    methods: {
      formatText(text, fnc) {
        return fnc ? fnc(text) : text;
      },

      onShowMore() {
        this.showNum += this.step;
      },
    },
  };
</script>

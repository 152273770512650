<template>
  <v-textarea
    :value="textValue"
    @input="onEdit"
    :label="label"
    :rules="rules"
    :required="required"
    :error-messages="errors || []"
    v-bind="customProps"></v-textarea>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: 'form-textarea',
    props: ['value', 'label', 'rules', 'required', 'customProps'],
    data() {
      return {
        errors: [],
      };
    },
    computed: {
      textValue() {
        if (typeof this.value === 'object') return JSON.stringify(this.value);
        return this.value;
      },
    },
    methods: {
      onEdit(val) {
        if (this.rules && this.rules.length) {
          this.validate(this.rules, val);
        }

        this.$emit('input', val);
      },

      async validate(rules, value) {
        this.errors = [];

        await Promise.all(
          _.map(rules, async validator => {
            const result = await validator(value);
            if (result !== true) this.errors.push(result);
          })
        );

        const isValid = this.errors.length === 0;
        this.$emit('valid', isValid);
      },
    },
    created() {
      if (this.rules && this.rules.length) {
        this.validate(this.rules, this.textValue);
      }
    },
  };
</script>
<style scoped></style>

<template>
	<div>
		<div v-if="isAway" class="away-overlay d-flex justify-center align-center">
			<div class="text-center pa-4 white--text">
				<div
					class="title mt-2"
					style="max-width: 600px"
					v-text="
						$t(
							'Your session has ended due to inactivity. You will be redirected to the login page in 10 seconds.'
						)
					"
				></div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.away-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(33, 33, 33, 0.66);
	z-index: 9999;
	width: 100%;
	height: 100%;
	font-size: 10rem;
}

.away-debug {
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 9999;
}
</style>

<script>
import Vue from 'vue';
import store from '@/store';
import { debounce } from 'lodash';

export default {
	name: 'userActivityMonitoring',

	props: {
		heartBeat: {
			type: Boolean,
		},
	},

	data() {
		return {
			delay: 10 * 1000,
			isAway: false,

			events: ['mousemove', 'mouseup', 'keyup'],
			eventFn: {},
			hbInterval: null,
			init: false
		};
	},

	methods: {
		logout() {
			this.isAway = true;
			this.removeListeners();
			setTimeout(() => {
        store.dispatch('user/logout', {});
			}, 10 * 1000);
		},

		update(from) {
			window.$socket.emit(`/user/lastActivity`, { timestamp: Date.now() });
		},

		addListeners() {
			if (this.heartBeat) {
				this.hbInterval = setInterval(() => this.update('heartBeat'), this.delay);
				return;
			}

			this.events.forEach((e) => document.addEventListener(e, this.eventFn[e]));
		},

		removeListeners() {
			this.events.forEach((e) => document.removeEventListener(e, this.eventFn[e]));
		},

		onSocketConnected() {
			console.log('[userChanal] connect');
			window.$socket.emit('/join/user/userChannel', (e) => console.log(e));
			if (!this.heartBeat) {
				window.$socket.on('/user/lastActivity/timeout', () => this.logout());
			}
			this.addListeners();
		},

		onSocketDisconnected() {
			console.log('[userChanal] disconnect');
			window.$socket.emit('/leave/user/userChannel', () => {});
			window.$socket.off('/user/lastActivity/timeout');
			this.removeListeners();
		},
	},

	watch: {
		'$store.state.socket.connected': function(connected) {
			console.log('[userChanal] watch', connected);
			if (connected) {
				this.onSocketConnected();
				if (!this.init) {
					this.update('init');
					this.init = true;
				}
			} else {
				this.onSocketDisconnected();
			}
		},
	},

	async created() {
		const fn = debounce(() => this.update(), this.delay);
		this.eventFn = this.events.reduce((r, e) => {
			r[e] = fn;
			return r;
		}, {});

		Vue.i18n.add('ru', {
			'Your session has ended due to inactivity. You will be redirected to the login page in 10 seconds.':
				'Ваш сеанс завершен из-за неактивности. Вы будете перенаправлены на страницу логина через 10 секунд.',
		});
		Vue.i18n.add('en', {
			'Your session has ended due to inactivity. You will be redirected to the login page in 10 seconds.':
				'Your session has ended due to inactivity. You will be redirected to the login page in 10 seconds.',
		});
	},
};
</script>

export const prod = {
  root: [
    {
      path: '/chatWindow',
      access: ['_ALL'],
      children: [
        {
          path: 'chat',
          name: 'chat',
          children: [
            {
              path: 'chatSideBar',
              name: 'chat-side-bar',
              children: [
                {
                  path: 'createNewSession',
                  name: 'create-new-session',
                  access: [
                    '_SKILLSET_EDITOR_ROOT',
                    '_SKILLSET_EDITOR_DEVELOPER',
                    '_SKILLSET_EDITOR_ANALYST',
                    '_SKILLSET_VIEWER',
                    '_NLP_MASTER',
                    '_NLP_VIEWER',
                    '_FAQ_MASTER',
                    '_FAQ_VIEWER',
                    '_FAQ_EDITOR',
                    '_DOCSOURCES_EDITOR',
                    '_DOCUMENTS_EDITOR',
                    '_DOCUMENTS_VIEWER',
                    '_SETTINGS_EDITOR',
                    '_SETTINGS_EDITOR_CONFIGS',
                    '_SETTINGS_EDITOR_PLATFORMS',
                    '_SETTINGS_VIEWER',
                    '_USERS_EDITOR',
                    '_USERS_VIEWER',
                    '_LOGS_VIEWER',
                    '_LOGS_DEBUG',
                    'OPERATOR',
                    'OPERATOR_READ',
                    '_OPERATOR_ANALYST',
                    '_TEACHER',
                    '_MONITORING',
                    '_LIST_SESSIONS_VIEWER',
                    '_RETRAINING_MASTER',
                    '_RETRAINING_VIEWER',
                    '_MORE_VIEWER',
                    'TRAFFIC_MANAGER',
                    '_SWAGGER_VIEWER',
                    '_SETTINGS_PRECLASSIFY_EDITOR',
                    '_SETTINGS_PRECLASSIFY_VIEWER',
                    '*',
                  ],
                },
              ],
            },
            {
              path: 'chatSessionView',
              name: 'chat-session-view',
              children: [
                {
                  path: 'chatSessionViewHeadline',
                  name: 'create-new-session-headline',
                  children: [
                    {
                      path: 'error',
                      name: 'error',
                      access: ['_TEACHER'],
                    },
                    {
                      path: 'closeSession',
                      name: 'close-session',
                      access: [
                        '_SKILLSET_EDITOR_ROOT',
                        '_SKILLSET_EDITOR_DEVELOPER',
                        '_SKILLSET_EDITOR_ANALYST',
                        '_SKILLSET_VIEWER',
                        '_NLP_MASTER',
                        '_NLP_VIEWER',
                        '_FAQ_MASTER',
                        '_FAQ_VIEWER',
                        '_FAQ_EDITOR',
                        '_DOCSOURCES_EDITOR',
                        '_DOCUMENTS_EDITOR',
                        '_DOCUMENTS_VIEWER',
                        '_SETTINGS_EDITOR',
                        '_SETTINGS_EDITOR_CONFIGS',
                        '_SETTINGS_EDITOR_PLATFORMS',
                        '_SETTINGS_VIEWER',
                        '_USERS_EDITOR',
                        '_USERS_VIEWER',
                        '_LOGS_VIEWER',
                        '_LOGS_DEBUG',
                        'OPERATOR',
                        'OPERATOR_READ',
                        '_OPERATOR_ANALYST',
                        '_TEACHER',
                        '_MONITORING',
                        '_LIST_SESSIONS_VIEWER',
                        '_RETRAINING_MASTER',
                        '_RETRAINING_VIEWER',
                        '_MORE_VIEWER',
                        'TRAFFIC_MANAGER',
                        '_SWAGGER_VIEWER',
                        '_SETTINGS_PRECLASSIFY_EDITOR',
                        '_SETTINGS_PRECLASSIFY_VIEWER',
                        '*',
                      ],
                    },
                    {
                      path: 'debug',
                      name: 'debug',
                      access: [
                        '_SKILLSET_EDITOR_ROOT',
                        '_SKILLSET_EDITOR_DEVELOPER',
                        '_SKILLSET_EDITOR_ANALYST',
                        '_LOGS_DEBUG',
                        'OPERATOR',
                      ],
                    },
                    {
                      path: 'createTest',
                      name: 'create-test',
                      access: ['_SKILLSET_EDITOR_ROOT', '_SKILLSET_EDITOR_DEVELOPER'],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'chatToolbar',
          name: 'chat-toolbar',
          children: [
            {
              path: 'adminpanel',
              name: 'adminpanel',
              access: [
                '_SKILLSET_EDITOR_ROOT',
                '_SKILLSET_EDITOR_DEVELOPER',
                '_SKILLSET_EDITOR_ANALYST',
                '_SKILLSET_VIEWER',
                '_NLP_MASTER',
                '_NLP_VIEWER',
                '_FAQ_MASTER',
                '_FAQ_VIEWER',
                '_FAQ_EDITOR',
                '_DOCSOURCES_EDITOR',
                '_DOCUMENTS_EDITOR',
                '_DOCUMENTS_VIEWER',
                '_SETTINGS_EDITOR',
                '_SETTINGS_EDITOR_CONFIGS',
                '_SETTINGS_EDITOR_PLATFORMS',
                '_SETTINGS_VIEWER',
                '_USERS_EDITOR',
                '_USERS_VIEWER',
                '_LOGS_VIEWER',
                '_LIST_SESSIONS_VIEWER',
                '_RETRAINING_MASTER',
                '_RETRAINING_VIEWER',
                '_MORE_VIEWER',
                '_LOGS_DEBUG',
                '_SWAGGER_VIEWER',
                '_SETTINGS_PRECLASSIFY_EDITOR',
                '_SETTINGS_PRECLASSIFY_VIEWER',
              ],
            },
            {
              path: 'openDebug',
              name: 'open-debug',
              access: [
                '_SKILLSET_EDITOR_ROOT',
                '_SKILLSET_EDITOR_DEVELOPER',
                '_SKILLSET_EDITOR_ANALYST',
                '_LOGS_DEBUG',
                'OPERATOR',
              ],
            },
            {
              path: 'sessionList',
              name: 'session-list',
              access: [
                '_SKILLSET_EDITOR_ROOT',
                '_SKILLSET_EDITOR_DEVELOPER',
                '_SKILLSET_EDITOR_ANALYST',
                '_SKILLSET_VIEWER',
                '_NLP_MASTER',
                '_NLP_VIEWER',
                '_FAQ_MASTER',
                '_FAQ_VIEWER',
                '_FAQ_EDITOR',
                '_DOCSOURCES_EDITOR',
                '_DOCUMENTS_EDITOR',
                '_DOCUMENTS_VIEWER',
                '_SETTINGS_EDITOR',
                '_SETTINGS_EDITOR_CONFIGS',
                '_SETTINGS_EDITOR_PLATFORMS',
                '_SETTINGS_VIEWER',
                '_USERS_EDITOR',
                '_USERS_VIEWER',
                '_LOGS_VIEWER',
                '_LOGS_DEBUG',
                'OPERATOR',
                'OPERATOR_READ',
                '_OPERATOR_ANALYST',
                '_TEACHER',
                '_MONITORING',
                '_LIST_SESSIONS_VIEWER',
                '_RETRAINING_MASTER',
                '_RETRAINING_VIEWER',
                '_MORE_VIEWER',
                '_SWAGGER_VIEWER',
                'TRAFFIC_MANAGER',
                '_SETTINGS_PRECLASSIFY_EDITOR',
                '_SETTINGS_PRECLASSIFY_VIEWER',
                '*',
              ],
            },
            {
              path: 'logout',
              name: 'logout',
              access: [
                '_SKILLSET_EDITOR_ROOT',
                '_SKILLSET_EDITOR_DEVELOPER',
                '_SKILLSET_EDITOR_ANALYST',
                '_SKILLSET_VIEWER',
                '_NLP_MASTER',
                '_NLP_VIEWER',
                '_FAQ_MASTER',
                '_FAQ_VIEWER',
                '_FAQ_EDITOR',
                '_DOCSOURCES_EDITOR',
                '_DOCUMENTS_EDITOR',
                '_DOCUMENTS_VIEWER',
                '_SETTINGS_EDITOR',
                '_SETTINGS_EDITOR_CONFIGS',
                '_SETTINGS_EDITOR_PLATFORMS',
                '_SETTINGS_VIEWER',
                '_USERS_EDITOR',
                '_USERS_VIEWER',
                '_LOGS_VIEWER',
                '_LOGS_DEBUG',
                'OPERATOR',
                'OPERATOR_READ',
                '_OPERATOR_ANALYST',
                '_TEACHER',
                '_MONITORING',
                '_LIST_SESSIONS_VIEWER',
                '_RETRAINING_MASTER',
                '_RETRAINING_VIEWER',
                '_MORE_VIEWER',
                'TRAFFIC_MANAGER',
                '_SWAGGER_VIEWER',
                '_SETTINGS_PRECLASSIFY_EDITOR',
                '_SETTINGS_PRECLASSIFY_VIEWER',
                '*',
              ],
            },
          ],
        },
      ],
    },
  ],
};

/* eslint-disable quotes */
import { mapState, mapActions, mapGetters } from 'vuex';
import { ADD_TOAST_MESSAGE } from '@/plugins/vuex-toastr/module';

import moment from 'moment';
import _ from 'lodash';

export default {
  name: 'session-details',
  props: ['color'],

  components: {},

  data() {
    return {
      showScrollToEndBtn: false,
      showNewMessageNotification: false,
      realtime: null,

      toggleBtnUp: null,
      activeMode: false,
    };
  },

  computed: {
    ...mapState({
      session: state => state.debugToolbar.session,
    }),

    sid() {
      return this.session.id;
    },
    status() {
      return this.session.state;
    },

    fields() {
      const template = [
        {
          name: 'User',
          icon: 'person',
          custom: session => {
            return session.user ? `${session.user.username} (${session.language})` : '';
          },
        },
        { name: 'Platform', icon: 'desktop_windows', path: 'platform.name' },
        { name: 'Perceptron', icon: 'dashboard', path: 'perceptron.name' },
        { name: 'Channel', icon: 'chat_bubble_outline', path: 'uiChannel' },
        {
          name: 'TTL',
          custom: session => {
            return `${session.ttl} ms`;
          },
        },
      ];

      if (!this.session) return [];

      return _.map(template, item => {
        return {
          name: item.name,
          icon: item.icon,
          value: item.custom ? item.custom(this.session) : _.get(this.session, item.path),
        };
      });
    },

    title() {
      return this.session.title;
    },
    createdAt() {
      return moment(this.session.createdAt).format('HH:mm:ss:SSS DD.MM.YYYY');
    },
    updatedAt() {
      return moment(this.session.updatedAt).format('HH:mm:ss:SSS DD.MM.YYYY');
    },
  },

  methods: {
    ...mapActions('sessionView', [
      'setMessages',
      'setInputHeight',
      'receiveMessage',
      'editMessage',
      'changeOperatorModeHasBeenChanged',
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE,
    }),

    openLeftSidebar() {
      this.activeMode = !this.activeMode;
    },
  },
};

import { render, staticRenderFns } from "./awesome-vuetify-jitsi.vue?vue&type=template&id=700de1ea&scoped=true&"
import script from "./awesome-vuetify-jitsi.js?vue&type=script&lang=js&"
export * from "./awesome-vuetify-jitsi.js?vue&type=script&lang=js&"
import style0 from "./awesome-vuetify-jitsi.styl?vue&type=style&index=0&id=700de1ea&prod&lang=stylus&scoped=true&"
import style1 from "./awesome-vuetify-jitsi.vue?vue&type=style&index=1&id=700de1ea&prod&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700de1ea",
  null
  
)

export default component.exports
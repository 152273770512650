<template>
  <v-navigation-drawer
    left
    permanent
    :mini-variant="!activeDebugToolbar"
    mini-variant-width="70"
    width="420">
    <v-layout class="fill-height" no-gutters>
      <v-navigation-drawer
        absolute
        left
        touchless
        color="grey lighten-3"
        mini-variant
        mini-variant-width="70"
        permanent>
        <DebugToolbar />
      </v-navigation-drawer>

      <v-card flat tile width="350" style="margin-left: 70px" v-show="activeDebugToolbar">
        <DebugSidebar />
      </v-card>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
  import { mapState } from 'vuex';
  import DebugToolbar from './components/DebugToolbar/DebugToolbar.vue';
  import DebugSidebar from './components/DebugSidebar/DebugSidebar.vue';

  export default {
    name: 'Debug',

    components: {
      DebugToolbar,
      DebugSidebar,
    },

    computed: {
      ...mapState({
        activeDebugToolbar: state => state.debugToolbar.activeDebugToolbar,
      }),
    },
  };
</script>

<style scoped></style>

import _ from 'lodash';

export default {
  name: 'form-string',
  props: ['value', 'label', 'rules', 'required', 'customProps'],
  data() {
    return {
      errors: [],
    };
  },
  computed: {
    textValue() {
      let text = '';

      if (this.customProps && this.customProps.prefix) {
        text = (this.value || '').replace(this.customProps.prefix, '');
      } else {
        text = this.value;
      }

      return text;
    },
  },
  methods: {
    onEdit(val) {
      if (this.rules && this.rules.length) this.validate(this.rules, val);
      let prefix = this.customProps && this.customProps.prefix ? this.customProps.prefix : '';

      this.$emit('input', `${prefix}${val}`);
    },

    async validate(rules, value) {
      this.errors = [];

      await Promise.all(
        _.map(rules, async validator => {
          const result = await validator(value);
          if (result !== true) this.errors.push(result);
        })
      );

      const isValid = this.errors.length === 0;
      this.$emit('valid', isValid);
    },
  },
  created() {
    if (this.rules && this.rules.length) this.validate(this.rules, this.value);
  },
};

<template>
  <div>
    <DebugDetails
      :color="color"
      :title="title"
      :storeName="storeName"
      :fields="fields"
      :searchFields="searchFields" />
  </div>
</template>

<script>
  import moment from 'moment';
  import { mapActions } from 'vuex';
  import { ADD_TOAST_MESSAGE } from '@/plugins/vuex-toastr/module';
  import DebugDetails from '../DebugDetails/DebugDetails.vue';

  export default {
    name: 'result-details',
    props: ['color'],

    components: {
      DebugDetails,
    },

    data() {
      return {
        title: 'Result',
        storeName: 'sResults',
        fields: [
          { name: 'createdAt', format: this.formatDate },
          { name: 'kbId' },
          { name: 'percent' },
          { name: 'initiator' },
          { name: 'state' },
        ],
        searchFields: [],

        showEventDialog: false,
        displayDataIndex: -1,
      };
    },

    methods: {
      ...mapActions({ addToast: ADD_TOAST_MESSAGE }),

      formatDate(date) {
        return moment(date).format('HH:mm:ss:SSS DD.MM.YYYY');
      },
      transform(data) {
        return JSON.stringify(data, null, 4);
      },
    },
  };
</script>

<style>
  .floatButton {
    bottom: 16px;
    right: 16px;
  }
</style>

import Vue from 'vue';
/* eslint-disable */
import { request, getOptions, AccessToken } from './api';

export const state = {
  elements: [],
  count: null,
  className: 'Event',
};

export const getters = {};

// actions
export const actions = {
  async create({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('addElement', data);
    }
  },

  async patchOrCreate({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('patch', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'patch',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async replaceOrCreate({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/replaceOrCreate';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async upsertWithWhere({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/upsertWithWhere';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async exists({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/{id}/exists';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['id'] !== undefined) {
      queryParameters['id'] = parameters['id'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async findById({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/{id}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async replaceById({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/{id}/replace';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('post', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'post',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async find({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('get', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'get',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElements', data);
    }
  },

  async findOne({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/findOne';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['filter'] !== undefined) {
      queryParameters['filter'] = parameters['filter'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('get', getOptions().domain + path, body, queryParameters, form, config);
  },

  async updateAll({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/update';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async deleteById({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/{id}';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('delete', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      await request('delete', getOptions().domain + path, body, queryParameters, form, config);
      commit('deleteElement', parameters.id); //hardcode for deleteId
    }
  },

  async count({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/count';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['where'] !== undefined) {
      queryParameters['where'] = parameters['where'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('get', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'get',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setCount', data);
    }
  },

  async patchAttributes({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/{id}/';
    let body;
    let queryParameters = {};
    let form = {};
    path = path.replace('{id}', `${parameters['id']}`);
    if (parameters['id'] === undefined) {
      return Promise.reject(new Error('Missing required  parameter: id'));
    }
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    if (parameters.__native) {
      return request('patch', getOptions().domain + path, body, queryParameters, form, config);
    } else {
      let data = await request(
        'patch',
        getOptions().domain + path,
        body,
        queryParameters,
        form,
        config
      );
      commit('setElement', data);
    }
  },

  async createChangeStream({ commit }, parameters) {
    const config = parameters;
    let path = '/Events/change-stream';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['options'] !== undefined) {
      queryParameters['options'] = parameters['options'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },
};

// mutations
export const mutations = {
  setElements(state, elements) {
    state.elements = elements && elements.data ? elements.data : elements;
  },
  setElement(state, element) {
    let index = _.findIndex(state.elements, { id: element.id });
    if (index < 0) {
      state.elements.push(element);
    } else {
      Vue.set(state.elements, index, element);
    }
  },
  addElement(state, element) {
    state.elements.push(element);
  },
  deleteElement(state, id) {
    let index = _.findIndex(state.elements, { id });
    if (index > 0) state.element.splice(index, 1);
  },
  setCount(state, count) {
    state.count = count;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import NotifyCollection from '../../classes/NotifyServiceCollection';
import { registerServiceWorker } from '@/plugins/notify.js';

const state = {
  ...NotifyCollection.state,
};

const getters = {};

const actions = {
  ...NotifyCollection.actions,
  async initPushService({ commit, dispatch }, params) {
    const getToken = await registerServiceWorker();
    if (getToken) {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        const token = await getToken();
        try {
          await dispatch('subscribe', {
            data: {
              token: token,
              userId: params.userId,
              type: 0,
            },
          });
        } catch (e) {
          console.log(e);
          return;
        }
      } else {
        console.log('permission denied');
        return;
      }
    }
  },
};

const mutations = {
  ...NotifyCollection.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

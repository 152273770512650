<template>
  <div>
    <DebugDetails
      :color="color"
      :title="title"
      :storeName="storeName"
      :fields="fields"
      :searchFields="searchFields" />
  </div>
</template>

<script>
  import moment from 'moment';
  import DebugDetails from '../DebugDetails/DebugDetails.vue';

  export default {
    name: 'prompt-details',
    props: ['color'],

    components: {
      DebugDetails,
    },

    data() {
      return {
        title: 'Prompt',
        storeName: 'sPrompts',
        fields: [
          { name: 'createdAt', format: this.formatDate },
          { name: 'elementType' },
          { name: 'elementId' },
          { name: 'method' },
          { name: 'textKey' },
          { name: 'textValue' },
        ],
        searchFields: ['elementType', 'elementId', 'method', 'textKey', 'textValue'],
      };
    },

    methods: {
      formatDate(date) {
        return moment(date).format('HH:mm:ss:SSS DD.MM.YYYY');
      },
      transform(data) {
        return JSON.stringify(data, null, 4);
      },
    },
  };
</script>

<style></style>

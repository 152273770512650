const state = {
  active: true,
};

const getters = {};

const actions = {
  setLoader({ commit }, data) {
    commit('setActive', data);
  },
  loaderOn({ commit }) {
    commit('setActive', true);
  },
  loaderOff({ commit }) {
    commit('setActive', false);
  },
};

const mutations = {
  setActive(state, data) {
    state.active = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

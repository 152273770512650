/**
 * awesome-vuetify-table
 * (c) 2018 Anton Nosenko
 * @license MIT
 */

import FormGenerator from './src/component/FormGenerator.vue';

export default {// const formGenerator =
	install(Vue) {
		Vue.component(FormGenerator.name, FormGenerator);
	},
};

// if (typeof window !== 'undefined' && window.Vue) {
// 	window.Vue.use(formGenerator);
// }
// export default formGenerator;

import _ from "lodash";

export default {
	name: 'form-boolean',
	props: ['value', 'label', 'rules', 'required', 'customProps'],

	data() {
		return {
			errors: []
		}
	},

	created() {
		if (this.rules && this.rules.length) this.validate(this.rules, this.value);
		if (this.value === undefined) this.$emit('input', false);
	},

	computed: {
		bValue: {
			get() {
				return !!this.value;
			},
			set(val) {
				if (this.rules && this.rules.length) this.validate(this.rules, val);
				
				this.$emit('input', !!val);
			}
		}
	},

	methods: {		
		async validate(rules, value) {
			this.errors = [];

			await Promise.all(
				_.map(rules, async (validator) => {
					const result = await validator(value);
					if (result !== true) this.errors.push(result)
				})
			);

			const isValid = (this.errors.length === 0);
			this.$emit("valid", isValid);
		}
	}
};

<template>
  <div>
    <DebugDetails
      :color="color"
      :title="title"
      :storeName="storeName"
      :fields="fields"
      :searchFields="searchFields" />
  </div>
</template>

<script>
  import moment from 'moment';
  import DebugDetails from '../DebugDetails/DebugDetails.vue';

  export default {
    name: 'task-details',
    props: ['color'],

    components: {
      DebugDetails,
    },

    data() {
      return {
        title: 'Task',
        storeName: 'sTasks',
        fields: [
          { name: 'createdAt', format: this.formatDate },
          { name: 'parentSessionId' },
          { name: 'perceptronId' },
          { name: 'taskType' },
          { name: 'status' },
          { name: 'initiatorUserId' },
          { name: 'parameters' },
        ],
        searchFields: ['parentSessionId', 'perceptronId', 'taskType', 'status', 'initiatorUserId'],
      };
    },

    methods: {
      formatDate(date) {
        return moment(date).format('HH:mm:ss:SSS DD.MM.YYYY');
      },
      transform(data) {
        return JSON.stringify(data, null, 4);
      },
    },
  };
</script>

<style></style>

import Vue from 'vue';

import ApiClient from '../classes/api';
import AuthClient from '../classes/auth';

const apiDomain = `${window.location.protocol}//${window.location.host}/core/api`;
const authDomain = `${window.location.protocol}//${window.location.host}/auth/api`;

Vue.use(ApiClient, { domain: apiDomain });
Vue.use(AuthClient, { domain: authDomain });

export { apiDomain, authDomain };

<template>
  <div class="c-scroll" ref="vs" v-scroll.self="onScroll">
    <slot name="default"></slot>
  </div>
</template>

<script>
  export default {
    name: 'CustomScroll',

    props: {
      scrollDown: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        scroll: null,
        ops: {
          vuescroll: {
            mode: 'native', // slide, native
            sizeStrategy: 'number', // number, percent,
            detectResize: true,
            wheelScrollDuration: 0,
            wheelDirectionReverse: false,
          },
          scrollPanel: {
            // initialScrollY: false,
            // initialScrollX: false,
            scrollingX: false,
            scrollingY: true,
            // speed: 300,
            // easing: undefined,
            // verticalNativeBarPos: 'right',
          },
          rail: {
            background: '#838383',
            opacity: 0.5,
            size: '120px',
            specifyBorderRadius: '0px',
            gutterOfEnds: '0px',
            gutterOfSide: '-4px',
            keepShow: true,
            border: null,
          },
          bar: {
            showDelay: 2000,
            onlyShowBarOnScroll: true,
            keepShow: false,
            background: '#b4b4b4',
            opacity: 0.05,
            hoverStyle: false,
            specifyBorderRadius: false,
            minSize: 0,
            size: '4px',
            disable: false,
          },

          scrollButton: {
            enable: false,
            background: 'rgb(3, 185, 118)',
            opacity: 0,
            step: 180,
            mousedownStep: 30,
          },
        },
        settings: {
          suppressScrollX: true,
          maxScrollbarLength: false,
        },
      };
    },

    methods: {
      onScroll(evt) {
        const { scrollTop, scrollHeight, offsetHeight } = evt.target;
        const progress = scrollTop / (scrollHeight - offsetHeight);
        this.$emit('onScroll', progress);
      },
      scrollToDown() {
        if (!this.scroll) return;
        this.scroll.scrollTop = this.scroll.scrollHeight;
      },
    },

    mounted() {
      this.scroll = this.$refs['vs'];

      if (this.scrollDown) {
        this.scrollToDown();
      }
    },
  };
</script>

<style scoped lang="stylus">
  .c-scroll {
  	overflow-y: auto;
  	scrollbar-color: rgba(128,128,128, 0.3) transparent;

  	&::-webkit-scrollbar {
      width: 15px;
  		height: 15px;
  	}

  	.scroller::-webkit-scrollbar-track-piece  {
      background-color: #C2D2E4;
  	}

  	.scroller::-webkit-scrollbar-thumb:vertical {
  		height: 30px;
  		background-color: #0A4C95;
  	}

  	&::-webkit-scrollbar-track {
  		background-color: transparent;
  	}

  	&::-webkit-scrollbar-thumb {
  		background-color: rgba(128,128,128, 0.3);
  		outline: 0px solid slategrey;
  	}
  }
</style>

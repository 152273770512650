<template>
  <v-tooltip v-model="show" bottom>
    <template #activator="{ attrs }">
      <v-hover v-model="show" open-delay="1000">
        <v-btn
          v-bind="attrs"
          :disabled="isNotActive"
          :dark="!isNotActive"
          :light="isNotActive"
          :style="listStyle"
          class="choice-btn mt-1 text-left"
          color="primary"
          depressed
          small
          @click="onChoice(item.value)">
          <div ref="container" class="text-truncate" style="height: 100%">
            <span v-if="!disableNumbering" ref="numbering" v-text="`${index + 1}.`" />
            <span ref="text" v-text="`${item.title}`" />
          </div>
        </v-btn>
      </v-hover>
    </template>
    <span v-text="item.title" />
  </v-tooltip>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'ChoiceBtn',
    props: {
      isNotActive: Boolean,
      item: Object,
      params: Object,
      index: Number,
    },
    data: () => ({
      show: false,
      ignoreNextTooltip: false,

      messagesContainer: null,
    }),
    computed: {
      ...mapGetters('theme', ['textColorsHash']),

      disableNumbering() {
        return this.params.disableNumbering !== undefined ? this.params.disableNumbering : false;
      },
      listStyle() {
        const style = {};
        if (this.isNotActive) {
          if (this.textColorsHash['Disabled choice color']) {
            style.color = `${this.textColorsHash['Disabled choice color'].value} !important`;
          }
          if (this.textColorsHash['Disabled choice background']) {
            style.background = `${this.textColorsHash['Disabled choice background'].value} !important`;
          }
        } else {
          if (this.textColorsHash['Active choice color'])
            style.color = this.textColorsHash['Active choice color'].value;
          if (this.textColorsHash['Active choice background']) {
            style.background = `${this.textColorsHash['Active choice background'].value} !important`;
          }
        }
        return style;
      },
    },
    watch: {
      isNotActive() {
        setTimeout(() => {
          this.show = false;
        }, 1000);
      },
      show(value) {
        if (this.ignoreNextTooltip) {
          this.ignoreNextTooltip = false;
          this.show = false;
        }
        if (value) {
          window.addEventListener('mousemove', this.closeTooltip);
          this.messagesContainer?.addEventListener('scroll', this.closeTooltip);
        } else {
          window.removeEventListener('mousemove', this.closeTooltip);
          this.messagesContainer?.removeEventListener('scroll', this.closeTooltip);
        }
      },
    },
    mounted() {
      this.messagesContainer = document.querySelector('.messages-container');
    },
    methods: {
      ...mapActions('sessionView', ['sendMessage']),
      onChoice(value) {
        this.sendMessage({ message: value });
        this.show = false;
        this.ignoreNextTooltip = true;
      },
      closeTooltip() {
        this.show = false;
      },
    },
  };
</script>

<style lang="stylus">
  .choice-btn {
  	display: block;
  	min-width: 60px !important;
  	max-width: 100%;
  	border-radius: 8px;
  	text-transform: none !important;
  	font-size: 0.9rem !important;
  	font-weight: 500;
  }

  .choice-btn > .v-btn__content {
  	justify-content: left !important;
  }

   .text-truncate {
     &::after {
       content: '';
       display: block;
     }
   }
</style>

import Vue from 'vue';
import vuexI18n from 'vuex-i18n';
import store from '@/store';

import en from '@/langs/en.json';
import ru from '@/langs/ru.json';
// import uk from '@/langs/uk.json';

Vue.use(vuexI18n.plugin, store);

Vue.i18n.add('en', en);
Vue.i18n.add('ru', ru);
// Vue.i18n.add('uk', uk);
Vue.i18n.set('ru');

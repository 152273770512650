import { get } from 'lodash';
import ConfigCollection from '../../classes/ConfigCollection';

const state = {
  ...ConfigCollection.state,
  configs: {},
};

const getters = {
  defaultLanguage: function (state) {
    const env_lang = process.env.VUE_APP_LANGUAGE;
    const cfg_lang = (get(state, 'configs.defaultLanguage') || '').toLowerCase();
    const default_lang = 'en';

    return env_lang || cfg_lang || default_lang;
  },

  isLogoutDisabled: function (state) {
    return get(state, 'configs.disable_chat_logout', false);
  },

  isChips: function (state) {
    return get(state, 'configs.chatChips', false);
  },

  systemMessage: function (state) {
    return get(state, 'configs.chatSystemMessage', null);
  },

  tutorCommand: function (state) {
    return get(state, 'configs.tutorCommand', null);
  },
};

const actions = {
  ...ConfigCollection.actions,

  fetchConfigs: async function ({ commit, dispatch }) {
    try {
      const res = await dispatch('getChatConfigs', { __native: true });
      const configs = {};

      res.data.result.forEach(({ id, value }) => {
        try {
          configs[id] = JSON.parse(value);
        } catch (e) {
          configs[id] = value;
        }
      });
      commit('setConfigs', configs);
      console.log('cfg:', JSON.parse(JSON.stringify(configs)));
    } catch (e) {
      console.log(e);
    }
  },
};

const mutations = {
  ...ConfigCollection.mutations,

  setConfigs: function (state, data) {
    state.configs = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

/**
 * Convert array of strings to key-value hash
 * @param {array} items - array of strings
 */
import Regexp from 'path-to-regexp';
import _ from 'lodash';

import store from '@/store';
import { request } from '@/classes/api';

import { accessMaps } from '../accessMap';

const MODES = ['dev', 'test', 'prod'];

function toHash(items) {
  return _.reduce(
    items || [],
    (memo, role) => {
      memo[role] = true;
      return memo;
    },
    {}
  );
}

/**
 * Recursive method designed for 'flattern' tree-like node structure.
 * Each step compile full path, stores parent and form complete access rules for provided item and call self for childrens.
 * @param {object} item - accessMap node with local path and access rule changes
 * @param {object} parent - converted node with full path and access rules
 *
 * @returns array of compiled nodes
 */
function addRoutes(item, parent) {
  let result = [];

  // Form current node access rules
  const itemAccess = {
    access: toHash(item.access),
    read: toHash(item.read),
    deny: toHash(item.deny),
  };

  // Check and add rules that 'spreads' from parent nodes
  if (parent) {
    _.each(parent.access || [], role => {
      if (!itemAccess.deny[role] && !itemAccess.read[role] && !itemAccess.access[role])
        itemAccess.access[role] = true;
    });
    _.each(parent.read || [], role => {
      if (!itemAccess.deny[role] && !itemAccess.read[role] && !itemAccess.access[role])
        itemAccess.read[role] = true;
    });
    _.each(parent.deny || [], role => {
      if (!itemAccess.deny[role] && !itemAccess.read[role] && !itemAccess.access[role])
        itemAccess.deny[role] = true;
    });
  }

  // Extend path to current node, save parent, transform access rules back to arrays
  const fullPath = parent && parent.path ? `${parent.path}/${item.path}` : item.path;
  const parentNode = {
    path: fullPath,
    regex: Regexp(fullPath),
    parent: parent || null,
    access: _.keys(itemAccess.access),
    read: _.keys(itemAccess.read),
    deny: _.keys(itemAccess.deny),
  };
  result.push(parentNode);

  if (item.children) {
    _.each(item.children || [], child => {
      result = result.concat(addRoutes(child, parentNode));
    });
  }

  return result;
}

class Arbitre {
  constructor() {
    /**
     * Sorted array of paths with options and access rules
     */
    this._routeMap = [];
    /**
     * Array of root nodes represents website sections.
     * Used to determine where each user will start work.
     */
    this._rootRoutes = [];
    /**
     * Array of all roles mentioned in accessMap.
     * Used to determine if a role has access to adminpanel
     */
    this._allRoles = [];
    /**
     * Stores the result of previous { _routeMap } node access calculations
     * to speed up processing of subsequent requests.
     * Format:
     * { user.id }-{ path } = { access type }
     */
    this._cache = {};

    this.INSTANCE_TYPE = 'dev';
  }

  /**
   * Read local json structure and convert it to routeMap
   */
  _loadMap() {
    let raw;
    try {
      raw = accessMaps[this.INSTANCE_TYPE];

      if (raw) {
        raw = raw && raw.root ? raw.root : [];
      }
    } catch (e) {
      console.log('Arbitre (line : 15) | _loadMap | e : ', e);
      raw = [];
    }

    let buf = [];
    _.each(raw || [], item => {
      buf = buf.concat(addRoutes(item, null));
    });

    this._routeMap = _.orderBy(buf, item => item.path.length, ['desc']);

    this._rootRoutes = _.map(
      _.filter(raw, item => item.redirector !== false),
      item => item.path
    );

    this._allRoles = _.uniq(
      _.reduce(
        this._routeMap || [],
        (memo, item) => memo.concat([...item.access, ...item.read, ...item.deny]),
        []
      )
    );
    // Replace item at index using native splice
    this._allRoles.splice(_.findIndex(this._allRoles || [], '*'), 1, 'ADMIN');
  }

  /**
   * Find correspondence of the path to routeMap record
   * @param {string} path full string path
   *
   * @returns {object} routeMap record
   */
  _match(path) {
    let res = null;

    for (let i = 0; i < this._routeMap.length; i++) {
      if (path.match(this._routeMap[i].regex)) {
        res = this._routeMap[i];
        break;
      }
    }

    return res;
  }

  async init(domain) {
    try {
      let data = await request('get', domain + '/Systems/getEnv', null, {}, {}, {});
      if (data && data.data) {
        let it;
        if (!data.data.useSkillsetRepository) {
          it = 'dev';
        } else {
          it = (data.data.instanceType || 'dev').toLowerCase();
        }
        if (MODES.includes(it)) this.INSTANCE_TYPE = it;
        console.log('instance:', this.INSTANCE_TYPE);
      }
    } catch (e) {
      console.log('Arbitre (line : 111) | init | e : ', e);
    }

    this._loadMap();
    return this.INSTANCE_TYPE;
  }

  /**
   * Check user access to resource interpreted by path
   * @param {object} opts { user, path }
   *
   * @returns {string} that can be one on three variants [ 'access', 'deny', 'read' ]
   */
  check(opts) {
    if (!opts) opts = {};

    // 'override' for call check without paremeters e.g. Arbitre.check()
    if (!opts.user) opts.user = _.get(store, 'state.user.user', null);
    if (!opts.user) throw 'No user no access';

    // 'override' for call check with only first parameter and current path
    if (!opts.path) opts.path = _.get(store, 'state.route.path', null);

    // Transform roles to flat array of stings
    const roles = _.map(opts.user.roles || [], item => item.name);
    if (!roles.length) throw 'No roles no access';

    // Check for superuser full access
    if (_.indexOf(roles, 'ADMIN') >= 0) return 'access';
    // if (_.indexOf(roles, '_ALL') >= 0) return 'access';

    // add default wildcard role to each user
    roles.push('*');

    // first - search in cache
    const cached = this._cache[`${opts.user.id}-${opts.path}`];
    if (cached) return cached;

    const route = this._match(opts.path);
    if (!route) throw `There is no route in accessMap for <${opts.path}>`;

    const routeAccess = {
      access: toHash(route.access),
      read: toHash(route.read),
      deny: toHash(route.deny),
    };

    const decision = {
      access: false,
      read: false,
      deny: false,
    };
    _.each(roles, role => {
      if (!decision.access && routeAccess.access[role]) decision.access = true;
      if (!decision.read && routeAccess.read[role]) decision.read = true;
      if (!decision.deny && routeAccess.deny[role]) decision.deny = true;
    });

    let strDecision = 'deny';
    if (decision.access) {
      strDecision = 'access';
    } else if (decision.read) {
      strDecision = 'read';
    }

    // store result for this user in cache
    this._cache[`${opts.user.id}-${opts.path}`] = strDecision;

    return strDecision;
  }

  checkUser() {
    const uRoles = _.map(_.get(store, 'state.user.user.roles', []), item => item.name);
    console.log('Arbitre (line : 231) | checkUser | uRoles : ', uRoles);
    return _.intersection(this._allRoles, uRoles).length > 0;
  }

  /**
   * Reset stored arbitre desigions for all users.
   * Use if roles of some of users changes or can be potentionaly changed.
   */
  resetCache() {
    this._cache = {};
  }

  getRootRoute(opts) {
    const self = this;

    // 'override' for call check without paremeters e.g. Arbitre.getRootRoutes()
    if (!opts.user) opts.user = _.get(store, 'state.user.user', null);
    if (!opts.user) throw 'No roles no access';

    if (!self._rootRoutes || !self._rootRoutes.length) return null;
    console.log('Arbitre (line : 222) | getRootRoute | self._rootRoutes : ', self._rootRoutes);
    return _.find(self._rootRoutes, item => {
      if (item.redirector === false) {
        return false;
      }

      const verdict = self.check({
        user: opts.user,
        path: item,
      });

      return verdict != 'deny';
    });
  }
}

// Single cached instance for all application
export default new Arbitre();

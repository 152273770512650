<template>
  <div>
    <template v-if="activator === 'btn'">
      <div class="UIServer-text" v-html="params.prompt"></div>
    </template>

    <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="date"
      persistent
      :fullscreen="mobile"
      :hide-overlay="mobile"
      width="320px">
      <template v-slot:activator="{ on }">
        <template v-if="activator === 'btn'">
          <v-btn
            v-on="on"
            :disabled="isNotActive"
            color="primary"
            small
            depressed
            class="mt-2 list-btn">
            <v-icon small left>event</v-icon>
            <span>{{ $t('Pick date') }}</span>
          </v-btn>
        </template>

        <template v-if="activator === 'input'">
          <v-text-field
            v-on="on"
            v-model="text"
            :disabled="isNotActive"
            prepend-inner-icon="event"
            readonly
            single-line
            solo
            hide-details
            class="ma-0" />
        </template>
      </template>

      <v-card>
        <v-card-text class="pa-0">
          <div class="d-flex title pa-3 primary white--text">
            <v-icon dark left>event</v-icon>
            <span>{{ text }}</span>
          </div>

          <v-card v-if="pickerType === 'year'" flat tile height="330" style="overflow: auto">
            <v-list class="pa-0">
              <template v-for="i in 100">
                <v-list-item :key="i" @click="date = `${2025 - i}`">
                  <v-list-item-title
                    class="text-center"
                    :class="{ 'ma-0 primary--text title': date === `${2025 - i}` }"
                    >{{ 2025 - i }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-card>

          <template v-else>
            <v-date-picker
              :range="rangeMode"
              v-model="date"
              :type="pickerType"
              scrollable
              no-title
              :locale="$t('locale')"
              full-width
              class="elevation-0" />
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn @click="sendDate" color="primary" text>{{ $t('Send') }}</v-btn>
          <v-btn @click="modal = false" color="primary" text>{{ $t('Close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import moment from 'moment';

  export default {
    name: 'DatePicker',
    props: {
      params: {
        type: Object,
        default: () => ({}),
      },
      isNotActive: Boolean,
      activator: {
        type: String,
        default: 'btn', // btn, input
      },
    },
    data() {
      return {
        dateSimple: null,
        dateRange: ['2019-09-10', '2019-09-20'],
        modal: false,
      };
    },
    computed: {
      date: {
        get() {
          return this.rangeMode ? this.dateRange : this.dateSimple;
        },
        set(value) {
          if (this.rangeMode) {
            this.dateRange = value;
          } else {
            this.dateSimple = value;
          }
        },
      },

      text() {
        if (this.rangeMode) {
          const date = [...this.dateRange];
          date.sort();
          return `${moment(date[0]).format(this.format)} ~ ${moment(date[1]).format(this.format)}`;
        } else {
          return moment(this.dateSimple).format(this.format);
        }
      },

      mobile() {
        return this.$vuetify.breakpoint.name === 'xs';
      },

      rangeMode() {
        return this.params.range;
      },

      pickerType() {
        return this.params.pickerType || 'date'; // year month date
      },

      dateLen() {
        if (this.pickerType === 'date') return 10;
        if (this.pickerType === 'month') return 7;
        return 4;
      },

      format() {
        if (this.pickerType === 'date') return 'DD.MM.YYYY';
        if (this.pickerType === 'month') return 'MM.YYYY';
        return 'YYYY';
      },
    },
    methods: {
      ...mapActions('sessionView', ['sendMessage']),

      sendDate() {
        this.modal = false;

        this.sendMessage({ message: this.text });
      },
    },
    created() {
      this.dateSimple = new Date().toISOString().substr(0, this.dateLen);
      this.dateRange = [
        new Date().toISOString().substr(0, this.dateLen),
        new Date().toISOString().substr(0, this.dateLen),
      ];
    },
  };
</script>

<style scoped>
  .UIServer-text {
    background: linear-gradient(180deg, rgb(233, 248, 253), rgb(219, 240, 251));
    border-radius: 4px 44px 44px 44px;
    padding: 10px 25px;
    margin: 4px;
    width: max-content;
    font-size: 14px;
    max-width: 100%;
  }

  .list-btn {
    border-radius: 8px;
    text-transform: none !important;
  }
</style>

import { mapActions } from 'vuex';
import { ADD_TOAST_MESSAGE } from '@/plugins/vuex-toastr/module';

export default {
  name: 'event-details',
  props: ['value', 'color'],

  data() {
    return {
      event: {
        eventId: null,
        eventSuffix: null,
        data: {},
      },
      eventStructure: [
        {
          key: 'eventId',
          type: 'lookup',
          store: 'events',
          itemKey: 'id',
          itemValue: 'id',
          required: true,
        },
        {
          key: 'eventSuffix',
          type: 'lookup',
          store: 'parameters',
          itemKey: 'id',
          itemValue: 'id',
          required: true,
        },
        {
          key: 'data',
          type: 'code',
        },
      ],
      isValid: false,
    };
  },

  created() {
    const { id } = this.$route.params;
    if (id) {
      this.setFilter({ where: { sessionId: id } });
    }
  },

  methods: {
    ...mapActions({ addToast: ADD_TOAST_MESSAGE }),
    ...mapActions('events', ['setFilter']),
    ...mapActions('debugToolbar', ['createEvent']),

    async sendEvent() {
      try {
        const { id } = this.$route.params;
        await this.createEvent({
          data: {
            sessionId: id,
            ...this.event,
          },
          __native: true,
        });
        this.hideDialog();
      } catch (error) {
        console.log('EmitEventDialog (line : 68) | sendEvent | error : ', error);
        this.addToast({
          type: 'error',
          text: error,
        });
      }
    },
    hideDialog() {
      console.log('EmitEventDialog (line : 67) | hideDialog : ');
      this.$emit('input', false);
    },
    validate(value) {
      this.isValid = value;
    },
  },
};

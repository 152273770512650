import ParameterCollection from '@/classes/ParameterCollection';

const DEBUG = false;

// initial state
const state = {
  ...ParameterCollection.state,
  countAll: 0,
  filter: {},
};

// getters
const getters = {
  parameters: state => state.elements,
};

// actions
const actions = {
  ...ParameterCollection.actions,

  async fetch({ dispatch }, filter) {
    await dispatch('find', { filter });
  },
  async countAll({ commit, dispatch }, filter) {
    const countFilter = {
      where: filter.where || {},
    };

    const countAll = await dispatch('count', { ...countFilter, __native: true });
    commit('setCountAll', countAll);
  },

  async createElement({ dispatch }, newItem) {
    await dispatch('create', { data: newItem, __native: true });
  },
  async editElement({ dispatch }, item) {
    await dispatch('patchAttributes', {
      id: item.id,
      data: item,
      __native: true,
    });
  },
  async deleteElement({ dispatch }, data) {
    await dispatch('deleteById', { id: data.id, __native: true });
  },

  setFilter({ commit }, filter) {
    commit('setFilter', filter);
  },
};

// mutations
const mutations = {
  ...ParameterCollection.mutations,

  setCountAll(state, count) {
    state.countAll = count;
  },
  setFilter(state, filter) {
    state.filter = filter;
  },
  setElements(state, elements) {
    const reduce = elements && elements.data ? elements.data : elements;
    state.elements = [{ id: '*' }, ...reduce];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

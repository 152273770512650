<template>
  <div>
    <DebugDetails
      :color="color"
      :title="title"
      :storeName="storeName"
      :fields="fields"
      :searchFields="searchFields" />
  </div>
</template>

<script>
  import moment from 'moment';
  import DebugDetails from '../DebugDetails/DebugDetails.vue';

  export default {
    name: 'agent-details',
    props: ['color'],

    components: {
      DebugDetails,
    },

    data() {
      return {
        title: 'Agents',
        storeName: 'sAgents',
        fields: [
          { name: 'createdAt', format: this.formatDate },
          { name: 'agentId' },
          { name: 'platformId' },
          { name: 'eventId' },
          { name: 'eventSuffix', hideLabel: true },
          { name: 'result' },
        ],
        searchFields: ['agentId', 'eventId', 'eventSuffix'],
      };
    },

    methods: {
      formatDate(date) {
        return moment(date).format('HH:mm:ss:SSS DD.MM.YYYY');
      },
      transform(data) {
        return JSON.stringify(data, null, 4);
      },
    },
  };
</script>

<style></style>

<template>
  <v-list two-line subheader class="transparent pa-0">
    <v-subheader class="subtitle-1">{{ title }}</v-subheader>

    <!--		<v-divider/>-->

    <template v-if="socketConnected">
      <v-list-item-group v-model="list" color="primary">
        <v-list-item
          v-for="session in sessionList"
          :key="session.id"
          :to="`/${path}/${session.id}`"
          active-class="primary white--text">
          <v-list-item-content>
            <v-list-item-title class="layout align-center caption">
              <div class="mr-1" :class="`${pinColor}-circle`"></div>
              {{ session.date }}
            </v-list-item-title>

            <v-list-item-title class="body-2 text-truncate font-weight-bold">
              {{ session.headline || $t('Session') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <div v-if="!sessionList.length" class="body-2 grey--text text--darken-1 pa-3">
        {{ $t('No active session available') }}
      </div>

      <div v-show="sessionList.length < items.length" class="text-center py-3">
        <v-btn @click="showMore" color="grey" small text class="ma-1">
          {{ $t('Show more') }}
        </v-btn>
      </div>
    </template>

    <template v-if="!socketConnected">
      <v-skeleton-loader v-for="i in 3" :key="i" tile type="list-item-two-line" />
    </template>
  </v-list>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'SessionList',

    props: {
      title: {
        type: String,
        default: 'Title',
      },
      items: {
        type: Array,
        default: () => [],
      },
      limit: {
        type: Number,
        default: 5,
      },
      pinColor: {
        type: String,
        default: 'green', // green, gray
      },
    },

    data() {
      return {
        list: 0,
        step: 20,
        show: this.limit,
      };
    },

    computed: {
      ...mapState({
        socketConnected: state => state.socket.connected,
      }),

      sessionList() {
        return this.items.slice(0, this.show) || [];
      },

      path() {
        const { name = 'session' } = this.$route;
        return name === 'session' || name === 'empty' ? 'session' : 'fs/session';
      },
    },

    methods: {
      showMore() {
        this.show += this.step;
      },
    },
  };
</script>

<style scoped>
  .green-circle,
  .grey-circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .green-circle {
    background-color: #39ebe0;
  }

  .grey-circle {
    background-color: #92a0a6;
  }
</style>

import { mapState } from 'vuex';

import SessionDetails from './components/SessionDetails/SessionDetails.vue';
import EventDetails from './components/EventDetails/EventDetails.vue';
import ParameterDetails from './components/ParameterDetails/ParameterDetails.vue';
import AgentDetails from './components/AgentDetails/AgentDetails.vue';
import ListenerDetails from './components/ListenerDetails/ListenerDetails.vue';
import ResultDetails from './components/ResultDetails/ResultDetails.vue';
import SessionUIDetails from './components/SessionUIDetails/SessionUIDetails.vue';
import SessionAskedParameterDetails from './components/SessionAskedParameterDetails/SessionAskedParameterDetails.vue';
import TaskDetails from './components/TaskDetails/TaskDetails.vue';
import PromptDetails from './components/PromptDetails/PromptDetails.vue';

export default {
  name: 'debug-sidebar',

  components: {
    SessionDetails,
    EventDetails,
    ParameterDetails,
    AgentDetails,
    ListenerDetails,
    ResultDetails,
    SessionUIDetails,
    SessionAskedParameterDetails,
    TaskDetails,
    PromptDetails,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      debugMode: state => state.debugToolbar.activeDebugToolbar,
      activeSidebar: state => state.debugToolbar.activeSidebar,
    }),

    componentColor() {
      return this.activeSidebar.color;
    },
    componentName() {
      return this.activeSidebar.component;
    },
  },
};

<template>
  <v-form ref="editForm" v-model="isAllFieldsValid">
    <v-row no-gutters class="pa-1">
      <template v-for="item in resultStructure">
        <v-col cols="12" :key="item.key" v-if="vizor[item.key]" v-bind="layoutToBind(item.layout)">
          <component
            :is="
              item.isCustomComponent ? item.type.toLowerCase() : 'form-' + item.type.toLowerCase()
            "
            :bindedErrorMessages="errorMessages[item.key]"
            :uniqKey="item.key"
            :value="item.key ? dataModel[item.key] : dataModel"
            @input="input(item.key, $event)"
            :label="item.label || item.key"
            :required="item.required"
            :rules="rules[item.key]"
            :store="item.store"
            :item="item"
            :itemKey="item.itemKey"
            :itemValue="item.itemValue"
            :filter="filters[item.key]"
            :items="item.items || []"
            :sort="item.sort"
            :afterFetchFilter="item.afterFetchFilter"
            @valid="value => validate(item.key, value)"
            @binded-validation="bindedValidation"
            :customProps="item.isCustomComponent ? {} : item.customProps"
            v-bind="item.isCustomComponent ? item.customProps : {}" />
        </v-col>
      </template>
    </v-row>
  </v-form>
</template>

<script>
  import _ from 'lodash';

  import FormTextarea from './components/FormTextarea/FormTextarea.vue';
  import FormString from './components/FormString/FormString.vue';
  import FormNumber from './components/FormNumber/FormNumber.vue';
  import FormInteger from './components/FormInteger/FormInteger.vue';
  import FormBoolean from './components/FormBoolean/FormBoolean.vue';

  const FormLookup = () => import('./components/FormLookup/FormLookup.vue');
  const FormSelect = () => import('./components/FormSelect/FormSelect.vue');
  const FormCode = () => import('./components/FormCode/FormCode.vue');
  const FormCodeJSON = () => import('./components/FormCodeJSON/FormCodeJSON.vue');
  const FormGroup = () => import('./components/FormGroup/FormGroup.vue');
  const FormArray = () => import('./components/FormArray/FormArray.vue');
  const FormPercent = () => import('./components/FormPercent/FormPercent.vue');
  const FormColor = () => import('./components/FormColor/FormColor.vue');

  import Vue from 'vue';

  export default {
    name: 'awesome-vuetify-form',

    components: {
      'form-textarea': FormTextarea,
      'form-string': FormString,
      'form-number': FormNumber,
      'form-integer': FormInteger,
      'form-boolean': FormBoolean,
      'form-lookup': FormLookup,
      'form-select': FormSelect,
      'form-code': FormCode,
      'form-json': FormCodeJSON,
      'form-group': FormGroup,
      'form-array': FormArray,
      'form-percent': FormPercent,
      'form-color': FormColor,
    },

    //	props: ['structure', 'value'],
    props: {
      structure: Array,
      value: Object,
      bindedRules: Object,
    },
    data() {
      return {
        resultStructure: [],
        validFields: {},
        initialCopy: null,
        errorMessages: {},
      };
    },
    created() {
      if (this.bindedRules) {
        Object.keys(this.bindedRules).forEach(key => {
          Vue.set(this.errorMessages, key, []);
        });
      }

      this.resultStructure = [];
      this.$nextTick(() => {
        this.resultStructure = _.cloneDeep(this.structure);
        this.validFields = {};
        this.resultStructure.forEach(({ key, type, required = false, rules = [] }) => {
          if (rules.length) {
            this.validFields = { ...this.validFields, [key]: required ? false : true };
          } else {
            this.validFields = { ...this.validFields, [key]: true };
          }
        });
      });
    },

    computed: {
      isAllFieldsValid: {
        get() {
          return Object.keys(this.validFields).every(f => this.validFields[f]);
        },
        set() {
          this.validate();
        },
      },
      dataModel: {
        get() {
          const fixed = this.initDefaults(this.resultStructure);
          if (!this.initialCopy) {
            this.initialCopy = JSON.parse(JSON.stringify(fixed));
          }
          return fixed;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      rules() {
        const self = this;

        return this.resultStructure.reduce((memo, item) => {
          if (item.required) {
            if (item.rules) {
              memo[item.key] = [v => !!v || item.key + ' is required'].concat(item.rules);
            } else {
              memo[item.key] = [v => !!v || item.key + ' is required'];
            }
          } else {
            memo[item.key] = item.rules;
          }

          return memo;
        }, {});
      },
      vizor() {
        const self = this;

        return this.resultStructure.reduce((memo, item) => {
          if (item.visible !== undefined) {
            memo[item.key] = item.visible(self.value || {});
          } else {
            memo[item.key] = true;
          }
          return memo;
        }, {});
      },
      filters() {
        const self = this;

        return this.resultStructure.reduce((memo, item) => {
          if (item.filter) {
            let bufFilter = item.filter || {};
            if (typeof bufFilter === 'function') {
              bufFilter = bufFilter(self.value || {});
            }
            memo[item.key] = bufFilter;
          }

          return memo;
        }, {});
      },
    },

    methods: {
      bindedValidation(val) {
        const { value, uniqKey } = val;

        if (!uniqKey || _.isEmpty(this.bindedRules) || !this.bindedRules[uniqKey]) return;

        this.errorMessages[uniqKey] = [];
        if (this.bindedRules[uniqKey].relation) {
          this.errorMessages[this.bindedRules[uniqKey].relation] = [];
        }

        let subject;
        let relation;

        if (this.bindedRules[uniqKey].type && this.bindedRules[uniqKey].type === 'array') {
          subject = value[this.bindedRules[uniqKey].subjectIndex];
          relation = value[this.bindedRules[uniqKey].relationIndex];
        } else {
          subject = value;
          relation = this.value[this.bindedRules[uniqKey].relation];
        }

        this.bindedRules[uniqKey].rules.forEach(rule => {
          let ruleChecked = rule(subject, relation);
          if (ruleChecked !== true) this.errorMessages[uniqKey].push(ruleChecked);
        });
      },
      input(key, val) {
        let data = Object.assign({}, this.dataModel);
        if (key) {
          data[key] = val;
        } else {
          data = _.extend(data, val);
        }

        this.$emit('input', data);
      },
      layoutToBind(layout) {
        if (!layout) {
          return {
            xs12: true,
          };
        }
        let vBindLayout = {};
        for (let i = 0; i < layout.length; i++) {
          vBindLayout[layout[i]] = true;
        }
        return vBindLayout;
      },
      validate(key, result) {
        const self = this;

        if (key && result !== undefined && result !== null) {
          this.validFields = { ...this.validFields, [key]: result };
        }

        const isValid =
          this.resultStructure.reduce((memo, item) => {
            return memo && !!self.validFields[item.key];
          }, true) && Object.keys(this.errorMessages).every(key => !this.errorMessages[key].length);

        this.$emit('valid', isValid);
      },

      initDefaults(structure) {
        let fixed = this.value || {};

        _.forEach(structure, item => {
          if (this.value[item.key] == undefined) {
            if (item.default !== undefined) {
              fixed[item.key] = typeof item.default === 'function' ? item.default() : item.default;
            } else if (item.type.toLowerCase() === 'boolean') {
              fixed[item.key] = false;
            } else if (item.type.toLowerCase() === 'group') {
              fixed = _.extend(fixed, this.initDefaults(item.items));
            }
          } else {
            fixed[item.key] = this.value[item.key];
          }
        });

        return fixed;
      },
    },

    watch: {
      value(newValue) {
        const dirty = !_.isEqual(this.initialCopy, newValue);
        this.$emit('dirty', dirty);
      },
      structure(v) {
        this.resultStructure = [];

        for (let key in this.errorMessages) {
          this.errorMessages[key] = [];
        }

        this.$nextTick(() => {
          this.resultStructure = _.cloneDeep(v);
          this.validFields = {};
          this.resultStructure.forEach(({ key, rules = [], required = false }) => {
            if (rules.length) {
              this.validFields = { ...this.validFields, [key]: required ? false : true };
            } else {
              this.validFields = { ...this.validFields, [key]: true };
            }
          });
          this.validate();
        });
      },
    },
  };
</script>

<template>
  <div class="overflow-hidden fh">
    <v-toolbar flat tile dense :color="color" dark height="100px" class="py-0">
      <v-flex>
        <div class="mb-2">
          <span class="title">{{ title }}</span>
          <span class="ml-2">{{ elements.length }}</span>
        </div>

        <div>
          <v-text-field
            v-model="filter"
            label="Search"
            prepend-inner-icon="search"
            solo-inverted
            flat
            dense
            dark
            clearable
            hide-details></v-text-field>
        </div>
      </v-flex>
    </v-toolbar>

    <div class="scroll-zone grey lighten-4">
      <DetailsList v-if="!items || items.length" :items="items" :fields="fields" />

      <v-layout v-else class="justify-center align-center fill-height">
        <span class="title grey--text">No items</span>
      </v-layout>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';
  import DetailsList from './components/DetailsList/DetailsList.vue';

  export default {
    name: 'debug-details',

    props: {
      color: {
        type: String,
      },
      title: {
        type: String,
      },
      storeName: {
        type: String,
      },
      fields: {
        type: Array,
      },
      searchFields: {
        type: Array,
      },
    },

    components: {
      DetailsList,
    },

    data() {
      return {
        filter: '',
      };
    },

    computed: {
      elements() {
        return this.$store.getters[`debugToolbar/${this.storeName}`];
      },

      items() {
        const items = this.elements;

        if (this.filter && this.isSearch) {
          return items.filter((item, i) => {
            for (const k of this.searchFields) {
              const filter = new RegExp(`${_.escapeRegExp(this.filter.trim().toLowerCase())}`, 'g');
              if (k in item && filter.test(JSON.stringify(item[k]).toLowerCase())) return true;
            }
            return false;
          });
        }

        return items;
      },

      isSearch() {
        return !_.isEmpty(this.searchFields);
      },
    },
  };
</script>

<style>
  .fh {
    /*height: 100vh;*/
    height: calc(100%);
    height: calc(var(--vh, 1vh) * 100);
  }

  .scroll-zone {
    height: 100%;
    max-height: 100%;
    max-height: calc(var(--vh, 1vh) * 100 - 100px);
    overflow: auto;
  }
</style>

import { mapState, mapGetters, mapActions } from 'vuex';
import Chat from './components/Chat/Chat.vue';
import Debug from './components/Debug/Debug.vue';
import ChatToolbar from './components/Chat/components/ChatToolbar/ChatToolbar.vue';
import SystemMessage from './components/Chat/components/ChatSystemMessage/ChatSystemMessage.vue';

export default {
  name: 'chat-window',

  components: {
    Chat,
    Debug,
    ChatToolbar,
    SystemMessage,
  },

  data() {
    return {
      showInviteButton: false,
    };
  },

  computed: {
    ...mapGetters('theme', ['universalImage', 'bgOptions']),
    ...mapState('toolbar', ['debugMode']),
    ...mapState('socket', ['connected']),
    ...mapState('videochat', ['url']),
    ...mapGetters('mobile', ['mobile']),

    bgStyle() {
      const {
        repeat = 'no-repeat',
        size = '100% auto',
        position = 'center top',
        attachment = 'fixed',
      } = this.bgOptions;

      return {
        'background-image': `url(${this.universalImage('background-image')})`,
        'background-repeat': repeat,
        'background-size': size,
        'background-position': position,
        'background-attachment': attachment,
      };
    },
  },

  methods: {
    ...mapActions('videochat', ['connect', 'disconnect']),

    onHide() {
      this.disconnect();
    },
  },

  watch: {
    connected(isConnected) {
      if (isConnected) {
        this.connect();
      } else {
        this.disconnect();
      }
    },
  },

  created() {
    if (this.connected) this.connect();
  },

  destroyed() {
    window.$socket.emit('/leave/videochat');
    window.$socket.off('invite');
  },
};

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import store from '@/store/index.js';

export const registerServiceWorker = async () => {
  const versions = await store.dispatch('info/fetchVersions');
  if (_.get(versions, 'notify.installed', false)) {
    const data = await (await fetch('./firebase-config.json')).json();
    if (data && data.forebaseConfig && data.pvk) {
      const app = initializeApp(data.forebaseConfig);
      try {
        const registration = await navigator.serviceWorker.register('firebase-messaging-sw.js', {
          scope: 'firebase-cloud-messaging-push-scope',
        });
        const messaging = getMessaging(app);
        return async () => {
          return await getToken(messaging, {
            serviceWorkerRegistration: registration,
            vapidKey: data.pvk,
          });
        };
      } catch (e) {
        console.log(e);
        return null;
      }
    } else {
      console.log('firebase-config.json is missing');
      return null;
    }
  }
};

export default { registerServiceWorker };

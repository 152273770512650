<template>
  <div class="pa-2 body-2" v-bind="props">
    <div v-if="!hideLabel" class="layout align-center">
      <span class="caption font-weight-bold">{{ label }}:</span>

      <template v-if="isCode">
        <v-spacer />

        <v-dialog v-model="dialog" max-width="520" :fullscreen="fs" :hide-overlay="fs">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="blue-grey" small icon>
              <v-icon small>fas fa-book-open</v-icon>
            </v-btn>
          </template>

          <v-card tile color="">
            <v-card-title class="pa-0 blue-grey lighten-2 white--text">
              <span class="px-2 caption">{{ label }}</span>

              <v-spacer />

              <!--<v-btn @click="fs = !fs" x-small icon dark class="ma-1">-->
              <!--	<v-icon small>fas fa-expand-arrows-alt</v-icon>-->
              <!--</v-btn>-->

              <v-btn @click="dialog = false" x-small icon dark class="ma-1">
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text
              v-if="dialog"
              class="pa-0 grey lighten-4"
              style="max-height: 80vh; overflow: auto">
              <pre class="body-2 pa-3 fill-height" contenteditable>{{ displayCode }}</pre>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-btn @click="show = !show" small icon class="ma-0">
          <v-icon x-small color="grey darken-2">fas fa-{{ show ? 'file' : 'code' }}</v-icon>
        </v-btn>
      </template>
    </div>

    <div class="text-fix-overflow" :class="textClass">
      <template v-if="!isCode">
        <span>{{ displayText }}</span>
      </template>

      <div v-if="isCode">
        <div class="code-wrap blue-grey lighten-4 layout align-center">
          <v-flex :class="{ 'text-truncate': !show }">
            <pre class="code body-2" :class="{ 'text-truncate': !show }">{{
              show ? displayCode : displayText
            }}</pre>
          </v-flex>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: 'details-list-field',
    props: {
      label: {
        type: String,
      },
      text: {
        default: '',
        required: true,
      },
      hideLabel: {
        type: Boolean,
        default: false,
      },
      textClass: {
        type: String,
        default: '',
      },
      props: {
        type: Object,
        default: () => ({ xs: true }),
      },
    },

    data() {
      return {
        show: false,
        dialog: false,
        fs: false,
      };
    },

    computed: {
      displayText() {
        let text = '';

        try {
          text = _.isObject(this.text) ? JSON.stringify(this.text, null, 4) : this.text;
        } catch (e) {
          text = '';
        }

        return text;
      },

      displayCode() {
        return JSON.parse(this.displayText);
      },

      isCode() {
        return (
          _.isObject(this.text) || _.get(this.text, '0') === '{' || _.get(this.text, '0') === '['
        );
      },
    },

    methods: {
      isObject(val) {
        return _.isObject(val);
      },
    },
  };
</script>

<style scoped>
  .text-fix-overflow {
    overflow: hidden;
    max-width: 100%;
    word-wrap: break-word;
  }

  .code-wrap {
    padding: 4px;
    border-radius: 4px;
  }

  .code {
    overflow-y: auto;
    max-height: 10rem;
  }

  .code-wrap * {
    scrollbar-color: rgba(120, 120, 120, 0.5) rgba(120, 120, 120, 0);
    scrollbar-width: thin;
  }

  .code-wrap *::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  .code-wrap *::-webkit-scrollbar-thumb {
    background: rgba(125, 125, 125, 0.5);
    border-radius: 5px;
  }

  .code-wrap *::-webkit-scrollbar-track {
    background: rgba(125, 125, 125, 0.1);
  }

  .code-wrap *::-webkit-scrollbar-corner {
    border-radius: 5px;
    background: rgba(125, 125, 125, 0.25);
  }
</style>

<template>
  <div
    ref="list"
    :style="`position: absolute; bottom: ${inputHeight}px; width: 100%;`"
    class="grey lighten-5"
    v-resize.quiet="getSize">
    <v-chip-group show-arrows>
      <v-chip
        v-for="chip in list"
        :key="chip.text"
        @click="onClick(chip.text)"
        :disabled="disabled"
        small
        class="ma-1"
        :title="chip.text">
        {{ chip.title
        }}<!-- ({{ chip.n }})-->
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    name: 'chipsList',

    props: {
      items: {
        type: Array,
      },
      onClick: {
        type: Function,
        default: text => {
          console.log(text);
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      ...mapState('sessionView', ['inputHeight', 'chipsHeight']),

      list: function () {
        return this.items.map(el => {
          return { ...el, title: el.text.length <= 20 ? el.text : el.text.substr(0, 17) + '...' };
        });
      },
    },

    methods: {
      ...mapActions('sessionView', ['setChipsHeight']),

      getSize: function () {
        const chips = this.$refs['list'];
        if (!chips) return 0;

        this.setChipsHeight(chips.offsetHeight);
        // console.log('resize', chips.offsetHeight);
        return chips.offsetHeight;
      },
    },

    watch: {
      items: function () {
        this.$nextTick(() => {
          this.getSize();
        });
      },
    },

    mounted: function () {
      // console.log('mounted - chips list');
      this.getSize();
    },
  };
</script>

<style scoped></style>

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint
  no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["state"] }] */
import _ from 'lodash';
import Vue from 'vue';
import SessionEventCollection from '@/classes/SessionEventCollection';
import SessionCollection from '@/classes/SessionCollection';

// initial state
const state = {
  activeDebugToolbar: false,
  activeSidebar: {},

  session: {},

  sessionEvents: [],
  sessionParameters: [],
  sessionAgents: [],
  sessionListeners: [],
  sessionResults: [],
  sessionUIs: [],
  sessionAskedParameters: [],
  tasks: [],
  prompts: [],
};

// getters
const getters = {
  session: state => state.session,

  sEvents: state => state.sessionEvents,
  sParameters: state => _.uniqBy(state.sessionParameters, 'parameterId'),
  sAgents: state => state.sessionAgents,
  sListeners: state => state.sessionListeners,
  sResults: state => state.sessionResults,
  sUIs: state => state.sessionUIs,
  sAskedParameters: state => state.sessionAskedParameters,
  sTasks: state => state.tasks,
  sPrompts: state => state.prompts,

  eventsAmount: state => state.sessionEvents.length,
  parametersAmount: state => _.uniqBy(state.sessionParameters, 'parameterId').length,
  agentsAmount: state => state.sessionAgents.length,
  listenersAmount: state => state.sessionListeners.length,
  resultsAmount: state => state.sessionResults.length,
  sessionUIsAmount: state => state.sessionUIs.length,
  sessionAskedParametersAmount: state => state.sessionAskedParameters.length,
  tasksAmount: state => state.tasks.length,
  promptsAmount: state => state.prompts.length,
};

// actions
const actions = {
  createEvent: SessionEventCollection.actions.create,
  addEventListener: SessionCollection.actions.addEventListener,
  setParameter: SessionCollection.actions.setParameter,
  setResult: SessionCollection.actions.setResult,

  saveSession({ commit }, session) {
    commit('spreadSession', session);
  },

  setDebugToolbarMode({ commit }, value) {
    commit('setDebugToolbarMode', value);
  },
  setDebugSidebar({ commit }, value) {
    commit('setDebugToolbarMode', value.activeMode);
    commit('setActiveSidebar', value.sidebar);
  },

  addEvent({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionEvents',
      element: value,
    });
  },
  addParameter({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionParameters',
      element: value,
    });
  },
  clearParameter({ commit }, { parameterId }) {
    commit('clearParameter', parameterId);
  },
  clearAllParameter({ commit }) {
    commit('clearAllParameter');
  },

  addAgent({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionAgents',
      element: value,
    });
  },
  addListener({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionListeners',
      element: value,
    });
  },
  removeListener({ commit }, id) {
    // console.log(id);
    commit('delListenerById', id);
  },
  addResult({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionResults',
      element: value,
    });
  },
  addUI({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionUIs',
      element: value,
    });
  },
  addAskedParameter({ commit }, value) {
    commit('upsertElement', {
      name: 'sessionAskedParameters',
      element: value,
    });
  },
  addTask({ commit }, value) {
    commit('upsertElement', {
      name: 'tasks',
      element: value,
    });
  },
  addPrompt({ commit }, value) {
    commit('upsertElement', {
      name: 'prompts',
      element: value,
    });
  },
};

// mutations
const mutations = {
  setDebugToolbarMode(state, value) {
    state.activeDebugToolbar = value;
  },
  setActiveSidebar(state, value) {
    state.activeSidebar = value;
  },
  spreadSession(state, session) {
    state.session = session;

    // state.sessionEvents = session.sessionEvents.splice(0,3);
    state.sessionEvents = session.sessionEvents || [];
    state.sessionParameters = session.sessionParameters || [];
    state.sessionAgents = session.sessionAgents || [];
    state.sessionListeners = session.sessionListeners || [];
    state.sessionResults = session.sessionResults || [];
    state.sessionUIs = session.sessionUIs || [];
    state.sessionAskedParameters = session.sessionAskedParameters || [];
    state.tasks = session.tasks || [];
    state.prompts = session.prompts || [];
  },
  upsertElement(state, { name, element }) {
    const index = _.findIndex(state[name] || [], item => item.id === element.id);

    if (index >= 0) {
      Vue.set(state[name], index, element);
    } else {
      state[name].unshift(element);
    }
  },
  delListenerById(state, id) {
    const index = _.findIndex(state.sessionListeners || [], item => item.id === id);

    if (index + 1) {
      state.sessionListeners.splice(index, 1);
    } else {
      console.log('OMG ', id, index);
    }
  },

  clearParameter(state, parameterId) {
    const index = _.findIndex(
      state.sessionParameters || [],
      item => item.parameterId === parameterId
    );

    if (index >= 0) {
      console.log('clear', state.sessionParameters[index].parameterId);
      state.sessionParameters.splice(index, 1);
    }
  },
  clearAllParameter(state) {
    console.log('clear all');
    state.sessionParameters = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import moment from 'moment';
import { mapActions } from 'vuex';
import { ADD_TOAST_MESSAGE } from '@/plugins/vuex-toastr/module';
import DebugDetails from '../DebugDetails/DebugDetails.vue';
import EmitEventDialog from './components/EmitEventDialog/EmitEventDialog.vue';

export default {
  name: 'event-details',
  props: ['color'],

  components: {
    DebugDetails,
    EmitEventDialog,
  },

  data() {
    return {
      title: 'Event',
      storeName: 'sEvents',
      fields: [
        { name: 'createdAt', format: this.formatDate },
        { name: 'eventId' },
        { name: 'eventSuffix', hideLabel: true },
        { name: 'data' },
      ],
      searchFields: ['eventId', 'eventSuffix'],

      showEventDialog: false,
      displayDataIndex: -1,
    };
  },

  methods: {
    ...mapActions({ addToast: ADD_TOAST_MESSAGE }),

    formatDate(date) {
      return moment(date).format('HH:mm:ss:SSS DD.MM.YYYY');
    },
    transform(data) {
      return JSON.stringify(data, null, 4);
    },
  },
};

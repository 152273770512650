import MobileDetect from 'mobile-detect';
import vuetify from '@/plugins/vuetify';

const DEBUG = false;

// initial state
const state = {
  UserInfo: null,
  isFullScreenMode: false,
};

// getters
const getters = {
  mobile(store) {
    const { name } = vuetify.framework.breakpoint;
    return (store.UserInfo && store.UserInfo.mobile) || name === 'xs';
  },
  isFS(state) {
    return state.isFullScreenMode;
  },
};

// actions
const actions = {
  getUserInfo({ commit }) {
    const md = new MobileDetect(window.navigator.userAgent);
    const data = {
      mobile: !!md.mobile(),
      tablet: !!md.tablet(),
      userAgent: md.userAgent(),
      os: md.os(),
    };
    commit('setUserInfo', data);
  },
  enableFSMode({ commit }) {
    commit('setFSMode', true);
  },
  disableFSMode({ commit }) {
    commit('setFSMode', false);
  },
};

// mutations
const mutations = {
  setUserInfo(store, data) {
    store.UserInfo = data;
  },
  setFSMode(store, mode) {
    store.isFullScreenMode = mode;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import { mapGetters } from 'vuex';
import { parse as parseContentDisposition } from 'content-disposition';
import { downloadStream } from '@/classes/api';

export default {
  name: 'UIServerSticker',
  props: {
    message: Object,
  },
  data: () => ({
    file: null,
    fileName: '',
    loader: false,
  }),
  methods: {
    async getFile(id, language) {
      const file = await downloadStream(`/Documents/download/${language}?docId=${id}`);
      console.log('file: ', file);
      console.log('file.headers: ', file.headers['content-disposition']);
      console.log('typeof file.headers: ', typeof file.headers['content-disposition']);

      const contentDisposition = parseContentDisposition(file.headers['content-disposition']);
      this.fileName = contentDisposition?.parameters?.filename ?? id;

      const blob = new Blob([file.data], {
        type: file.headers['content-type'],
      });
      return URL.createObjectURL(blob);
    },
  },
  computed: {
    ...mapGetters('theme', ['universalImage']),
  },
  async mounted() {
    this.loader = true;
    this.file = await this.getFile(
      this.message.params.data.docId,
      this.message.params.data.language
    );
    this.loader = false;
  },
};

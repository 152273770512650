<template>
  <v-text-field
    type="number"
    :value="value"
    @input="onEdit"
    :label="label"
    :error-messages="errors || []"
    :required="required"
    v-bind="customProps"></v-text-field>
</template>

<script>
  import _ from 'lodash';

  function isFloatOrEmpty(n) {
    if (undefined === n || null === n || '' === n) {
      return true;
    }

    if (typeof n == 'number') {
      return true;
    }

    return !isNaN(n - 0);
  }

  export default {
    name: 'form-number',
    //	props: ['value', 'label', 'rules', 'required', 'customProps'],
    props: {
      value: Number,
      label: String,
      rules: {
        type: Array,
        default: () => [],
      },
      required: {
        type: Boolean,
        default: false,
      },
      customProps: Object,
      bindedErrorMessages: {
        type: Array,
        default: () => [],
      },
      uniqKey: String,
    },
    data() {
      return {
        allRules: [],
        errors: [],
      };
    },
    created() {
      this.allRules = [v => isFloatOrEmpty(v) || 'Must be Float', ...(this.rules || [])];

      if (this.value) this.validate(this.value + '');
    },
    methods: {
      parse(raw) {
        let result = undefined;

        try {
          if (isNaN(raw)) throw 'Not a number';

          if (raw) result = parseFloat(raw);
        } catch (error) {
          console.log('FormNumber (line : 42) | parse | error : ', error);
          this.errors.push('Number format incorrect');
        }

        return result;
      },

      onEdit(val) {
        this.errors = [];

        this.validate(val);
        const result = this.parse(val);

        this.$emit('input', result);
      },

      async validate(value) {
        let isValid;

        if (this.allRules && this.allRules.length) {
          console.log('FormNumber (line : 62) | value : ', value);
          await Promise.all(
            _.map(this.allRules, async validator => {
              const result = await validator(value);
              if (result !== true) this.errors.push(result);
            })
          );
        }

        this.$emit('binded-validation', { value: value, uniqKey: this.uniqKey });
        await Promise.resolve();

        isValid = this.errors.length === 0;
        this.$emit('valid', isValid);
      },
    },
    watch: {
      bindedErrorMessages(errorMessages, oldErrorMessages) {
        this.errors = this.errors.filter(
          error => !oldErrorMessages.find(oldErr => oldErr === error)
        );
        this.errors.push(...errorMessages);
      },
    },
  };
</script>

<template>
  <v-dialog v-if="value" v-model="value" persistent max-width="500px">
    <v-card :color="color" dark>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
    </v-card>
    <v-card>
      <v-card-text>
        <awesome-vuetify-form
          v-model="item"
          :structure="structure"
          @valid="validate"></awesome-vuetify-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click.native="hideDialog()">{{ $t('Close') }}</v-btn>
        <v-btn color="blue darken-1" text @click="send()" :disabled="!isValid">{{
          $t('Send')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex';
  import { ADD_TOAST_MESSAGE } from '@/plugins/vuex-toastr/module';

  export default {
    name: 'event-details',
    props: ['value', 'color'],

    components: {},

    data() {
      return {
        title: 'Emit parameter',
        item: {},
        structure: [
          {
            key: 'parameterId',
            type: 'lookup',
            store: 'sessionParameters',
            itemKey: 'id',
            itemValue: 'id',
            required: true,
          },
          {
            key: 'value',
            type: 'String',
            required: true,
          },
          {
            key: 'initiator',
            type: 'String',
          },
        ],
        isValid: false,
      };
    },

    methods: {
      ...mapActions({ addToast: ADD_TOAST_MESSAGE }),
      ...mapActions('debugToolbar', ['setParameter']),

      async send() {
        try {
          const { id } = this.$route.params;
          await this.setParameter({ id, ...this.item });
          this.hideDialog();
        } catch (error) {
          console.log('EmitEventDialog (line : 68) | sendEvent | error : ', error);
          this.addToast({ type: 'error', text: error });
        }
      },
      getSessionId() {
        const { id } = this.$route.params;
        return id;
      },

      hideDialog() {
        this.$emit('input', false);
      },
      validate(value) {
        this.isValid = value;
      },
    },
  };
</script>

<style scoped></style>

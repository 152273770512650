import Vue from 'vue';
/* eslint-disable */
import { request, getOptions, AccessToken } from './api';

export const state = {
  elements: [],
  count: null,
  className: 'NotifyService',
};

export const getters = {};

// actions
export const actions = {
  async subscribe({ commit }, parameters) {
    const config = parameters;
    let path = '/NotifyServices/subscribe';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },

  async notifications({ commit }, parameters) {
    const config = parameters;
    let path = '/NotifyServices/notifications';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },
};

// mutations
export const mutations = {
  setElements(state, elements) {
    state.elements = elements && elements.data ? elements.data : elements;
  },
  setElement(state, element) {
    let index = _.findIndex(state.elements, { id: element.id });
    if (index < 0) {
      state.elements.push(element);
    } else {
      Vue.set(state.elements, index, element);
    }
  },
  addElement(state, element) {
    state.elements.push(element);
  },
  deleteElement(state, id) {
    let index = _.findIndex(state.elements, { id });
    if (index > 0) state.element.splice(index, 1);
  },
  setCount(state, count) {
    state.count = count;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

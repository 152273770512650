import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { ADD_TOAST_MESSAGE } from '@/plugins/vuex-toastr/module';

export default {
  name: 'debug-ui',

  data() {
    return {
      toolbar: [
        {
          color: '#26c6da',
          icon: 'description',
          component: 'session-details',
          tooltip: this.$t('Session details'),
        },
        {
          color: '#9c27b0',
          icon: 'event',
          component: 'event-details',
          counter: 'eventsAmount',
          tooltip: this.$t('Events'),
        },
        {
          color: '#74989e',
          icon: 'gavel',
          component: 'parameter-details',
          counter: 'parametersAmount',
          tooltip: this.$t('Parameters'),
        },
        {
          color: '#a1cee5',
          icon: 'fa fa-user-secret',
          component: 'agent-details',
          counter: 'agentsAmount',
          tooltip: this.$t('Agents'),
        },
        {
          color: '#f44336',
          icon: 'fa fa-eye',
          component: 'listener-details',
          counter: 'listenersAmount',
          tooltip: this.$t('Listeners'),
        },
        {
          color: '#bd72d0',
          icon: 'fa fa-cubes',
          component: 'result-details',
          counter: 'resultsAmount',
          tooltip: this.$t('Results'),
        },
        {
          color: '#1389f4',
          icon: 'fa fa-comment',
          component: 'sessionUI-details',
          counter: 'sessionUIsAmount',
          tooltip: this.$t('Session UIs'),
        },
        {
          color: '#fb8b00',
          icon: 'fa fa-question-circle',
          component: 'sessionAskedParameter-details',
          counter: 'sessionAskedParametersAmount',
          tooltip: this.$t('Session asked parameters'),
        },
        {
          color: '#6abf69',
          icon: 'fa fa-tasks',
          component: 'task-details',
          counter: 'tasksAmount',
          tooltip: this.$t('Tasks'),
        },
        {
          color: '#94466D',
          icon: 'fa fa-font',
          component: 'prompt-details',
          counter: 'promptsAmount',
          tooltip: this.$t('Prompts'),
        },
      ],

      item: null,
    };
  },

  computed: {
    ...mapState({
      socketConnected: state => state.socket.connected,
      session: state => state.debugToolbar.session,
      activeMode: state => state.debugToolbar.activeDebugToolbar,
      activeComponent: state => state.debugToolbar.activeSidebar,
    }),
    ...mapGetters('debugToolbar', [
      'eventsAmount',
      'parametersAmount',
      'agentsAmount',
      'listenersAmount',
      'resultsAmount',
      'sessionUIsAmount',
      'sessionAskedParametersAmount',
      'tasksAmount',
      'promptsAmount',
    ]),

    enabled() {
      return !!(this.session && this.session.id);
    },
    active() {
      return this.activeComponent ? this.activeComponent.component : '';
    },
  },

  methods: {
    ...mapActions('debugToolbar', [
      'setDebugToolbarMode',
      'setDebugSidebar',
      'saveSession',
      'addEvent',
      'addParameter',
      'addAgent',
      'addListener',
      'addResult',
      'addUI',
      'addAskedParameter',
      'addTask',
      'addPrompt',
      'removeListener',
      'clearParameter',
      'clearAllParameter',
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE,
    }),

    joinToSession(id) {
      if (!id) return;

      window.$socket.on('debug/sessionEvent', this.addEvent);
      window.$socket.on('debug/sessionAgent', this.addAgent);
      window.$socket.on('debug/sessionResult', this.addResult);
      window.$socket.on('debug/sessionUI', this.addUI);
      window.$socket.on('debug/sessionAskedParameter', this.addAskedParameter);
      window.$socket.on('debug/sessionTask', this.addTask);
      window.$socket.on('debug/sessionPrompt', this.addPrompt);
      window.$socket.on('debug/sessionListener', this.addListener);
      window.$socket.on('debug/sessionListener/delete', this.removeListener);
      window.$socket.on('debug/sessionParameter', this.addParameter);
      window.$socket.on('debug/sessionParameter/clear', this.clearParameter);
      window.$socket.on('debug/sessionParameter/clearAll', this.clearAllParameter);

      window.$socket.emit('/join/Debug/Session', { id }, data => {
        if (data.error) {
          this.addToast({ type: 'error', text: data.error });
        } else {
          this.saveSession(data);
        }
      });
    },

    leaveSession(id) {
      if (!id) return;

      window.$socket.emit('/leave/Debug/Session', { id });

      window.$socket.off('debug/sessionEvent', this.addEvent);
      window.$socket.off('debug/sessionAgent', this.addAgent);
      window.$socket.off('debug/sessionResult', this.addResult);
      window.$socket.off('debug/sessionUI', this.addUI);
      window.$socket.off('debug/sessionAskedParameter', this.addAskedParameter);
      window.$socket.off('debug/sessionTask', this.addTask);
      window.$socket.off('debug/sessionPrompt', this.addPrompt);
      window.$socket.off('debug/sessionListener', this.addListener);
      window.$socket.off('debug/sessionListener/delete', this.removeListener);
      window.$socket.off('debug/sessionParameter', this.addParameter);
      window.$socket.off('debug/sessionParameter/clear', this.clearParameter);
      window.$socket.off('debug/sessionParameter/clearAll', this.clearAllParameter);
    },

    count(getterName) {
      return this[getterName];
    },

    openLeftSidebar(componentName) {
      const toolbarItem = _.find(this.toolbar, item => item.component === componentName);

      if (!this.activeMode) {
        this.setDebugSidebar({
          activeMode: true,
          sidebar: toolbarItem,
        });
      } else if (toolbarItem.component === this.active) {
        this.setDebugToolbarMode(false);
      } else {
        this.setDebugSidebar({
          activeMode: this.activeMode,
          sidebar: toolbarItem,
        });
      }
    },

    onClickFull() {
      const { id } = this.$route.params;
      if (!id) return;

      const { protocol, host } = window.location;
      window.open(`${protocol}//${host}/operator#/debug/${id}`, '_blank');
    },
  },

  watch: {
    socketConnected: function (connected) {
      const { id } = this.$route.params;
      if (!id) return;

      if (connected) {
        this.joinToSession(id);
      } else {
        this.leaveSession(id);
      }
    },
    '$route.params.id': function (newId, oldId) {
      if (oldId) this.leaveSession(oldId);
      if (newId) this.joinToSession(newId);
    },
  },

  created() {
    const { id } = this.$route.params;

    if (this.socketConnected && id) {
      this.joinToSession(id);
    }
  },

  destroyed() {
    const { id } = this.$route.params;
    this.leaveSession(id);
    this.setDebugToolbarMode(false);
  },
};

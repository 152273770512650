import Vue from 'vue';
/* eslint-disable */
import { request, getOptions, AccessToken } from './auth';

export const state = {
  className: 'UserEx',
};

export const getters = {};

// actions
export const actions = {
  async userLogout({ commit }, parameters) {
    const config = parameters;
    let path = '/user/logout';
    let body;
    let queryParameters = {};
    let form = {};
    if (parameters['data'] !== undefined) {
      body = parameters['data'];
    }
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (parameterName) {
        queryParameters[parameterName] = parameters.$queryParameters[parameterName];
      });
    }
    return request('post', getOptions().domain + path, body, queryParameters, form, config);
  },
};

// mutations
export const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import AwesomeVuetifyJitsiComponent from './src/awesome-vuetify-jitsi.vue'

import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

const AwesomeVuetifyJitsi = {
	install(Vue, options) {
		Vue.component('vue-draggable-resizable', VueDraggableResizable);
		Vue.component(AwesomeVuetifyJitsiComponent.name, AwesomeVuetifyJitsiComponent);
	}
};

if (typeof window !== 'undefined' && window.Vue) {
	window.Vue.use(AwesomeVuetifyJitsi);
}

export default AwesomeVuetifyJitsi;
